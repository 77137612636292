const StatisticsData7=[
    {
        id: 1,
        xAxis: ["< 1", "Year"],
        yAxis: "22625"
    },
    {
        id: 2,
        xAxis: "1",
        yAxis: "6687"
    },
    {
        id: 3,
        xAxis: "2",
        yAxis: "5864"
    },
    {
        id: 4,
        xAxis: "3",
        yAxis: "5398"
    },
    {
        id: 5,
        xAxis: "4",
        yAxis: "4884"
    },
    {
        id: 6,
        xAxis: "5",
        yAxis: "4430"
    },
    {
        id: 7,
        xAxis: "6",
        yAxis: "4102"
    },
    {
        id: 8,
        xAxis: "7",
        yAxis: "3690"
    },
    {
        id: 9,
        xAxis: "8",
        yAxis: "3398"
    },
    {
        id: 10,
        xAxis: "9",
        yAxis: "3006"
    },
    {
        id: 11,
        xAxis: "10",
        yAxis: "2774"
    },
    {
        id: 12,
        xAxis: "11",
        yAxis: "2741"
    },
    {
        id: 13,
        xAxis: "12",
        yAxis: "2522"
    },
    {
        id: 14,
        xAxis: "13",
        yAxis: "2475"
    },
    {
        id: 15,
        xAxis: "14",
        yAxis: "2176"
    },
    {
        id: 16,
        xAxis: "15",
        yAxis: "1799"
    },
    {
        id: 17,
        xAxis: "16",
        yAxis: "1410"
    },
    {
        id: 18,
        xAxis: "17",
        yAxis: "807"
    },
    {
        id: 19,
        xAxis: "18+",
        yAxis: "89"
    }

]
export default StatisticsData7;