import React from 'react';
import {BarChart} from "components/ui";
import interactiveStatisticsCharts from 'data/interactive-statistics-series-data.js';
import StatisticsData7 from 'data/data-7-enter-out-of-home-care-age-at-removal-aod.js';
import StatisticsEntry from 'templates/statistics-detail-page-layout';

const isBrowser = typeof window !== "undefined"

const StatisticsPage7=({location})=>{
        let thisLocationPathname = ''
        if (isBrowser) {
            thisLocationPathname = window.location.pathname;
        }
        const pageData = interactiveStatisticsCharts.filter(data=>data.id === 3)
        const interactiveStatisticsPageData = pageData[0];
    return (
        <StatisticsEntry
        location={location}
        title={interactiveStatisticsPageData.title}
        description={interactiveStatisticsPageData.metaDescription}
        author={interactiveStatisticsPageData.author}
        year={interactiveStatisticsPageData.year}
        note={<span>Estimates based on <strong className="text-underline">children who entered out of home care</strong> during the Fiscal Year</span>}
        chartNotes={<p>N = 80,877</p>}
        fileName={interactiveStatisticsPageData.fileName}
        fileSize={interactiveStatisticsPageData.fileSize}>
            <BarChart
            title={interactiveStatisticsPageData.title}
            chartLabels={[...StatisticsData7.map(data=>data.xAxis)]}
            chartData={[...StatisticsData7.map(data=>data.yAxis)]}
            xAxisLabel="Age"
            yAxisLabel="Number of children"
            chartLayoutVertical={true}
            yAxisMax={25000}
            yAxisIncrement={5000}
            chartLabelsVertical={true}
            />
                <p>
                    Children from birth to age 5 make up more than half (61.7%, N=49,888) of the children placed in out-of-home care with parental AOD as a condition associated with removal during Fiscal Year 2021.  
                    Children age 6 — 12 make up 27.5% (N=22,233) and children age 13 — 18+ make up 10.8% (N=8,756) of the children removed from their homes and placed in out-of-home care during the same time period.
                </p>
        </StatisticsEntry>
    )
}
export default StatisticsPage7;